import { defineStore, acceptHMRUpdate } from 'pinia'
import { IUserBank, IPackagePromotion, IVirtualNetwork } from '~/types/common'
import {
  IBanks,
  IMomos,
  INicepayBanks,
  INicepayData,
  IPlanData,
  IProviderGoPay,
  IViettelPays,
  IWithdrawBank
} from '~/types/account'
import { ICard } from '~/types/card'
import { BaseInvoice } from '~/types/history/transaction'
import { RANGES } from '~/constants/promotions'

interface IState {
  banks: IBanks[],
  nicepayData: INicepayData[] | null
  momos: IMomos[]
  viettelPays: IViettelPays[]
  nicepayBanks: INicepayBanks[]
  withdrawBanks: IWithdrawBank[]
  providerGoPay: IProviderGoPay
  packages: IPackagePromotion
  userBanks: IUserBank[]
  crypto_v2: IVirtualNetwork[]
  cardList: {
    VIETTEL: ICard,
    VINAPHONE: ICard,
    MOBIFONE: ICard,
    ZING?: ICard
  },
  plan: IPlanData
  transactionDetail: BaseInvoice | null
  transactionType: string
}

export const useAccountStore = defineStore('account', {
  state: () : {
    banks: any[];
    rankConfig: {};
    packages: {
      subtype: string;
      multiplier: number;
      name: string;
      description: string;
      max_amount: number;
      id: number;
      type: string;
      promotion: number
    };
    nicepayData: any[];
    cardList: {
      ZING: { rate: number; value: any[]; status: number };
      VIETTEL: { rate: number; value: any[]; status: number };
      VINAPHONE: { rate: number; value: any[]; status: number };
      MOBIFONE: { rate: number; value: any[]; status: number }
    };
    transactionType: string;
    viettelPays: any[];
    recharge: {};
    withdrawBanks: any[];
    userBanks: any[];
    momos: any[];
    providerGoPay: { GOPAY: { ewallets: any[]; bankings: any[] } };
    plan: {
      deposit_amount: number;
      rolling: null;
      created_time: string;
      multiplier: number;
      name: string;
      description: string;
      package_id: number;
      type: string;
      turnover: number;
      rolling_info: null
    };
    crypto_v2: any[];
    transactionDetail: BaseInvoice | null;
    nicepayBanks: any[]
  } => ({
    banks: [],
    nicepayData: [],
    momos: [],
    viettelPays: [],
    nicepayBanks: [],
    withdrawBanks: [],
    providerGoPay: { GOPAY: { ewallets: [], bankings: [] } },
    recharge: {},
    rankConfig: {},
    packages: {
      name: '',
      description: '',
      multiplier: 0,
      promotion: 0,
      max_amount: 0,
      type: '',
      id: 0,
      subtype: ''
    },
    userBanks: [],
    crypto_v2: [],
    cardList: {
      VIETTEL: { status: 0, rate: 0, value: [] },
      MOBIFONE: { status: 0, rate: 0, value: [] },
      VINAPHONE: { status: 0, rate: 0, value: [] },
      ZING: { status: 0, rate: 0, value: [] }
    },
    plan: {
      name: '',
      type: '',
      deposit_amount: 0,
      multiplier: 0,
      rolling: null,
      rolling_info: null,
      package_id: 0,
      turnover: 0,
      created_time: '',
      description: ''
    },
    transactionDetail: null as BaseInvoice | null,
    transactionType: ''
  }),
  actions: {
    mutate(payload: any) {
      (this.$state as any)[payload.property] = payload.with
    },
    resetState() {
      Object.assign(this.$state)
    },
    initDataDeposit(payload: any) {
      Object.keys(payload).forEach((key) => {
        if (payload[key]) {
          this.mutate({ property: key, with: payload[key] })
        }
      })
    },
    setRecharge(payload: any) {
      this.recharge = payload
    },
    setRankConfig(payload: any) {
      this.rankConfig = payload
    },
    updateListUserBank(payload: IUserBank[]) {
      this.userBanks = payload
    },
    updateCardList(payload: any) {
      this.cardList = payload
    },
    updatePlan(payload: IPlanData) {
      this.plan = payload
    },
    updateNicepayData(payload: INicepayData[]) {
      this.nicepayData = payload
    },
    updateTransactionType(payload: string) {
      this.transactionType = payload
    },
    updateTransactionDetail(payload: BaseInvoice | null) {
      this.transactionDetail = payload
    }
  },
  getters: {
    rangesTableData(state) {
      return RANGES.map((item) => ({
        ...item,
        value: state.rankConfig?.refundRanges ? state.rankConfig?.refundRanges[item.key] : []
      })).filter((obj) => (obj.value?.length || 0) > 0)
    }
  },
  persist: true
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAccountStore, import.meta.hot))
}
